import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility, KfAnchorLink } from "@klickinc/kf-react-components";

const HeIsManny = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--transcript page--transcript-he-is-manny");
	}, []);

	return (
		<>
			<section className="transcript-section">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading ">
						Transcript
					</KfHeroUtility>
					<p className="uppercase mb-4">The following story is of an individual situation and may not be the typical experience for all patients. Consult your healthcare professional about the best treatment plan for you or your child. Participants are being paid by PTC therapeutics and are not medical professionals.</p>
					<h2 className='orange-heading'>A Real-World Story</h2>
					<div className="script">
						<div className="he-is-manny font-bold mt-4">
							<p>HE DREAMS</p>
							<p>HE PLAYS</p>
							<p>HE LAUGHS</p>
							<p>HE ACTS LIKE A KID</p>
							<p>BECAUSE HE CAN</p>
							<p>HE IS MANNY</p>
							<p className="no-flex">EMFLAZA<sup>®</sup> (deflazacort) is a prescription medicine used to treat Duchenne muscular dystrophy (DMD) in patients 2 years of age and older.</p>
							<p>Do not use if you have hypersensitivity, including allergic reactions, to deflazacort or any inactive ingredients.</p>
							<p>Duchenne muscular dystrophy affects 1 in 3,600-6,000 live male births.</p>
							<p>Manny was one of them.</p>
						</div>
						<div className="script--body">
							<p><span className="name">Manny:</span> <span className="copy">My name is Manny and I live in Ossining for seven years and I'm 15.</span></p>
							<p><span className="name">Lydia:</span> <span className="copy">My name is Lydia Aviles. I'm originally from Puerto Rico. My family live in Isabela, in the northwest side.</span></p>
							<p><span className="name">Manny:</span> <span className="copy">There's tasty food.</span></p>
							<p><span className="name">Lydia:</span> <span className="copy">And what else? What do you like practice there?</span></p>
							<p><span className="name">Manny:</span> <span className="copy">Surfing?</span></p>
							<p><span className="name">Lydia:</span> <span className="copy">Surfing.</span></p>
							<p><span className="name">Manny:</span> <span className="copy">There's a lot of mosquitos.</span></p>
							<p><span className="name">Lydia:</span> <span className="copy">[laughs]</span></p>
							<p className="font-bold">There are two corticosteroids used to treat boys with Duchenne muscular dystrophy and they are not the same.</p>
							<p><span className="name">Lydia:</span> <span className="copy">So, I remember Manny start to use the medication almost at 11. When Manny start with the prednisone at this rate, the body start to change. Because Manny was very skinny. When he used the prednisone, Manny had won a lot of weight. So that is the first part I'm scared or concerned. He looked in the mirror he said, “Why do I look in that way? I don't want to continue with this medication because maybe in the future, affect some part of my body.”</span></p>
							<p><span className="name">Lydia:</span> <span className="copy">And then after that, I meet the new doctor in New York. It's the MDA clinic. And he's a very good doctor. Excellent. The doctor continued the treatment, but he said–I remember he said, “I'm waiting for one medication, the name is EMFLAZA.” So, I say, “Oh!” Something showed me hope in this patch because it's very hard. And then Manny is start to look information about EMFLAZA. And then when he sees the doctor on the next visit he say, “Are you asking for my medication? When I start with EMFLAZA?” So, the doctor say, “Soon, Manny.”</span></p>
							<p className="font-bold">In time, Manny and his mom were able to work with the doctor to switch to EMFLAZA.</p>
							<p><span className="name">Lydia:</span> <span className="copy">The doctor always—he's in contact with us. He called me say, “You know what, I changed your scheduling for the appointment because I need to talk to you.” When I go there, he say, “Okay I'm gonna start the process with the EMFLAZA medication.”</span></p>
							<p className="font-bold">EMFLAZA is the first and only FDA-approved corticosteroid designed to help maintain muscle strength and function.</p>
							<p className="font-bold">EMFLAZA is the only corticosteroid approved by the FDA for the treatment of DMD in boys 2 years of age and older.</p>
							<p><span className="name">Lydia:</span> <span className="copy">And then he said, “We are lucky because I fighting five times for this medication.” When he said that I feel so, “Yes! He did it!” And then few days after that someone call me and say, “I call you because I want to schedule the delivery for the medication.” And then I say, “Wow, it's real!” Before he used EMFLAZA I remember Manny has, you know, a lot of change. One of the weight, he won more weight. And then when he start to use EMFLAZA just start to change a lot. A lot of changes. Very different than the medication before.</span></p>
							<p className="font-bold">Weight gain can be tough for any boy, but added weight to deteriorating muscles can be even harder to deal with.</p>
							<p className="font-bold">EMFLAZA may be able to help.</p>
							<p className="font-bold">Boys taking deflazacort experienced less weight gain compared to those taking prednisone</p>
							<p className="font-bold">Deflazacort: 27.9% (n=19/68);</p>
							<p className="font-bold">prednisone: 34.9% (n=22/63)</p>
							<p className="font-bold">Based on clinical study results. Individual weight changes may vary.</p>
							<p><span className="name">Lydia:</span> <span className="copy">He say, “Mommy, I feel good with this medication. I think I'm lucky.” “When I look in the mirror, I see a lot of, you know, a lot of changes in my life. Right now, I feel more confidence.” Manny is a big gift for me. He showed me to be more strong. I never give up. I feel very blessing because Manny has a good spirit. He's strong like me. When I saw him, I saw me in him. So it's something magical.</span></p>
							<p className="highlighted-copy">Learn more at <KfAnchorLink url='/' linkClass='underline'>EMFLAZA.com</KfAnchorLink></p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HeIsManny;

export function Head() {
	return (
		<Seo title="Video Transcript: He is Manny - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
